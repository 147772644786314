import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import { palette } from '@shared/tokens/palette.stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.din2014Regular,
    minHeight: '200px',
    padding: '0px',
  },

  header: {
    padding: '16px 24px',
    color: colors.primaryText,
    fontSize: '24px',
    fontFamily: fonts.din2014Regular,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '8px',
    padding: ' 10px 24px 16px ',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputWrapper: {
    display: 'flex',
    padding: '0px 24px',
    flexDirection: 'column',
    gap: '16px',
  },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },

  input: {
    paddingBottom: '14px',
  },
  textCount: {
    position: 'absolute',
    zIndex: -1,
    color: palette['grey-500'],
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    right: '25px',
    top: '141px',
  },
  text: {
    fontSize: typography['fontSizeCaption-1'],
    padding: '16px 24px 6px 24px',
    color: colors.secondaryText,
  },
});
