import { Button, Dropdown, Modal, PreloaderCircular, TextField } from '@sminex/sminex-uikit';
import { createPortal } from 'react-dom';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { FC, useEffect, useState } from 'react';
import { ModalStatus } from '@features/sms-email-status';
import { SmsResult } from '@pages/event/api/useFetchSms';
import { useToast } from '@shared/hooks/useToast';

interface IModalProps {
  closeHandle: () => void;
  sendSMS: (message: string) => Promise<void>;
  sendEmails: (message: string) => Promise<void>;
  active: boolean;
  isLoading: boolean;
  smsRecipients: SmsResult[];
  isAdmin: boolean;
}

const invitationTypes = [
  { id: 1, label: 'СМС', value: 'type1' },
  { id: 2, label: 'Email', value: 'type2' },
  { id: 3, label: 'СМС и Email', value: 'type3' },
];

const LENS_MESSAGE = 100;

export const ModalTickets: FC<IModalProps> = ({
  active,
  closeHandle,
  sendSMS,
  sendEmails,
  isLoading,
  smsRecipients,
  isAdmin,
}) => {
  const [message, setMessage] = useState('');
  const [selectedType, setSelectedType] = useState(invitationTypes[0]);
  const [isCustomLayout, setIsCustomLayout] = useState(false);
  const [isModalStatusVisible, setIsModalStatusVisible] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    setMessage('');
    setIsCustomLayout(false);
    setSelectedType(invitationTypes[0]);
  }, [active]);

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= LENS_MESSAGE) {
      setMessage(event.target.value);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() === '') {
      showToast('Введите сообщение для отправки', 'error');
      return;
    }
    try {
      switch (selectedType.value) {
        case 'type1': // Только SMS
          await sendSMS(message);
          showToast('СМС успешно отправлены', 'success');
          break;

        case 'type2': // Только Email
          await sendEmails(message);
          showToast('Email успешно отправлены', 'success');
          break;

        case 'type3': // SMS и Email
          await sendSMS(message);
          await sendEmails(message);
          showToast('СМС и Email успешно отправлены', 'success');
          break;

        default:
          showToast('Выберите тип отправки', 'error');
      }
      if (isAdmin) {
        closeHandle();
        return;
      }
      setIsCustomLayout(true); // Переход на разметку ModalStatus
    } catch (error) {
      showToast('Произошла ошибка при отправке приглашений', 'error');
    }
  };

  const handleDropdownChange = (value: string[]) => {
    const selectedOption = invitationTypes.find((option) => option.value === value[0]);

    if (selectedOption) {
      setSelectedType(selectedOption);
    }
  };

  if (!active) return null;

  return createPortal(
    <Modal
      active={active}
      settingsContainer={{ height: 'auto', width: '480px', padding: '0px' }}
      settingsHeader={{ padding: '0px' }}
      settingsFooter={{ padding: '0px' }}
    >
      <div {...stylex.props(styles.container)}>
        <p {...stylex.props(styles.header)}> {isLoading ? 'Отправить QR-билеты' : 'Отправить билеты'}</p>

        {isLoading ? (
          <>
            <div {...stylex.props(styles.loader)}>
              <PreloaderCircular variant="secondary" size="large" />
            </div>
            <p {...stylex.props(styles.text)}>
              Мы отправляем QR-билеты на электронную почту организатора. Этот билет необходим для прохода на мероприятие
            </p>
          </>
        ) : isCustomLayout ? (
          <ModalStatus closeHandle={closeHandle} smsRecipients={smsRecipients} />
        ) : isModalStatusVisible ? (
          <ModalStatus closeHandle={() => setIsModalStatusVisible(false)} smsRecipients={smsRecipients} />
        ) : (
          <div {...stylex.props(styles.inputWrapper)}>
            <Dropdown
              labelMessage="Тип приглашения *"
              options={invitationTypes}
              itemText="label"
              itemValue="value"
              value={selectedType}
              settings={{ width: '100%', cursor: 'text' }}
              changeFunc={handleDropdownChange}
            />
            <p {...stylex.props(styles.textCount)}>{message.length}/100</p>
            <TextField
              labelMsg="Текст приглашения *"
              changeFunction={changeHandler}
              placeholder="Введите текст"
              value={message}
              settings={{
                width: '100%',
                cursor: 'text',
                resize: 'vertical',
                minHeight: '78px',
              }}
              status={message.length < LENS_MESSAGE ? 'default' : 'error'}
              textareaMessage={
                message.length < LENS_MESSAGE ? String(message.length) : 'Максимальное количество символов'
              }
            />
          </div>
        )}

        {!isCustomLayout && !isModalStatusVisible && (
          <div {...stylex.props(styles.actions)}>
            <Button onClick={() => closeHandle()} variant="secondary" settings={{ padding: '8px 20px' }} size="small">
              Отмена
            </Button>
            {!isLoading && (
              <Button
                onClick={handleSendMessage}
                isDisabled={message.length < 1}
                variant="accent"
                settings={{ padding: '8px 20px' }}
                size="small"
              >
                Отправить
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal>,
    document.getElementById('modal') as Element
  );
};
